<template>
  <!-- form -->

  <b-modal
    :id="'modal-edit-type-'+type.id"
    :ref="'modal-edit-type-'+type.id"
    :title="'Editing ' + type.name.en"
    ok-title="Save"
    cancel-variant="outline-secondary"
    @show="resetModal"
    @hidden="resetModal"
    @ok="validationForm"
  >

    <validation-observer
      #default="{ handleSubmit }"
      ref="refTypeEdit"
    >
      <b-form
        class="py-1"
        @submit.prevent="handleSubmit(onSubmit)"
      >

        <div class="demo-inline-spacing">

          <b-form-checkbox
            v-model="typeData.required"
            checked="true"
            class="custom-control-success"
            name="required"
            switch
          >
            Required
          </b-form-checkbox>

          <b-form-checkbox
            v-model="typeData.multiple"
            checked="true"
            class="custom-control-success"
            name="multiple"
            switch
          >
            Multiple
          </b-form-checkbox>

          <b-form-checkbox
            v-model="typeData.published"
            checked="true"
            class="custom-control-success"
            name="multiple"
            switch
          >
            Public
          </b-form-checkbox>
        </div>

        <b-form-checkbox
          v-if="type.general === false"
          v-model="typeData.general"
          v-b-tooltip.hover.left="'This option cannot be undone after saving'"
          checked="true"
          class="custom-control-success mt-1"
          name="general"
          switch
        >
          General
          <span class="switch-icon-left">
            <feather-icon icon="GlobeIcon" />
          </span>
        </b-form-checkbox>

        <template v-if="typeData.general === false">

          <!-- Categories -->
          <validation-provider
            v-if="type.categories && type.categories.length > 0"
            #default="validationContext"
            name="Categories"
            rules="required"
          >
            <b-form-group
              label="Categories"
              label-for="categories"
            >

              <v-select
                v-if="Object.keys(getCurrentCategoriesById()).length > 0 && typeData.parent_id && getCurrentCategoriesById()[typeData.parent_id[0]]"
                :id="'category'+type.id"
                v-model="typeData.categories"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="resolveSubCategoriesById(typeData.parent_id[0])"
                :clearable="false"
                :reduce="val => val.value"
                :input-id="'category'+type.id"
                :multiple="true"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Sub Categories -->
          <validation-provider
            v-if="type.subcategories && type.subcategories.length > 0"
            #default="validationContext"
            name="Sub Categories"
            rules="required"
          >
            <b-form-group
              label="Sub Categories"
              label-for="sub categories"
            >

              <v-select
                v-if="Object.keys(getCurrentCategoriesById()).length > 0 && typeData.parent_id && getCurrentCategoriesById()[typeData.parent_id[0]]"
                :id="'subcategory'+type.id"
                v-model="typeData.categories"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="resolveSubCategoriesById(typeData.parent_id[0])"
                :multiple="true"
                :reduce="val => val.value"
                :input-id="'subcategory'+type.id"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </template>

        <!-- Name -->
        <validation-provider
          #default="validationContext"
          name="Name EN"
          rules="required"
        >
          <b-form-group
            label="Name EN"
            label-for="name_en"
          >
            <b-form-input
              :id="'name_en'+type.id"
              v-model="typeData.name.en"
              :state="getValidationState(validationContext)"
              trim
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider
          #default="validationContext"
          name="Name IT"
          rules="required"
        >
          <b-form-group
            label="Name IT"
            label-for="name_it"
          >
            <b-form-input
              :id="'name_it'+type.id"
              v-model="typeData.name.it"
              :state="getValidationState(validationContext)"
              trim
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-form>
    </validation-observer>

    <template v-if="$can('destroy', 'Type') && type.filters.length === 0">
      <div class="divider my-1">
        <div class="divider-text">
          Only for delete
        </div>
      </div>
      <a
        class="btn text-danger"
        @click="swalConfirmDelete(type.id)"
      >
        Delete
      </a>
    </template>

  </b-modal>
</template>
<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormCheckbox,
  BModal,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import store from '@/store'
import vSelect from 'vue-select'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useCategoriesList from '@/views/items/categories/categories-list/useCategoriesList'

export default {
  name: 'TypeEditButton',
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,
    BModal,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    type: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  setup(props, { emit }) {
    // Use toast
    const toast = useToast()

    const {
      fetchCategories,
      getCurrentCategories,
      getCurrentCategoriesById,
    } = useCategoriesList()

    const blankTypeData = {
      id: props.type.id,
      general: !!props.type.general,
      parent_id: props.type.subcategories && props.type.subcategories.length > 0 ? props.type.subcategories.map(data => data.parent_id) : props.type.categories.map(data => data.parent_id),
      categories: props.type.subcategories && props.type.subcategories.length > 0 ? props.type.subcategories.map(data => ({ label: `${data.name.it} - ${data.name.en}`, value: data.id })) : props.type.categories.map(data => ({ label: `${data.name.it} - ${data.name.en}`, value: data.id })),
      required: !!props.type.required,
      multiple: !!props.type.multiple,
      published: !!props.type.published,
      name: {
        en: props.type.name.en,
        it: props.type.name.it,
      },
    }

    const typeData = ref(JSON.parse(JSON.stringify(blankTypeData)))
    const resettypeData = () => {
      typeData.value = JSON.parse(JSON.stringify(blankTypeData))
    }

    const onSubmit = () => {
      const sendTypeData = typeData.value
      if (sendTypeData.general === false) {
        sendTypeData.categories = sendTypeData.categories[0].label ? sendTypeData.categories.map(data => data.value) : sendTypeData.categories
      }
      store.dispatch('types/updateType', sendTypeData)
        .then(() => {
          emit('refetch-data')
          toast({
            component: ToastificationContent,
            props: {
              title: 'Type update successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          console.log(error.response)
          Object.values(error.response.data)
            .forEach(message => {
              toast({
                component: ToastificationContent,
                props: {
                  title: message[0],
                  icon: 'AlertTriangleIcon',
                  variant: 'warning',
                },
              })
            })
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resettypeData)

    return {
      fetchCategories,
      getCurrentCategories,
      getCurrentCategoriesById,

      typeData,
      blankTypeData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  methods: {
    swalConfirmDelete(id) {
      this.$swal({
        title: 'Are you sure?',
        text: 'It will delete all filters of this type',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            store.dispatch('types/forceDeleteType', id)
              .then(() => {
                this.$emit('refetch-data')
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: 'Type has been deleted.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              })
          } else if (result.dismiss === 'cancel') {
            this.$swal({
              title: 'Cancelled',
              text: 'Type and his filters are safe :)',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
          return result.value
        })
    },
    resolveSubCategoriesById(parentId) {
      return this.getCurrentCategoriesById()[parentId]
        .map(type => ({
          label: `${type.name.it} - ${type.name.en}`,
          value: type.id,
        }))
    },
    validationForm(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.$refs.refTypeEdit.validate().then(success => {
        if (success) {
          this.handleSubmit()
        }
      })
    },
    resetModal() {
      this.typeData = this.blankTypeData
    },
    handleSubmit() {
      this.onSubmit()

      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs[`modal-edit-type-${this.typeData.id}`].toggle('#toggle-btn')
      })
    },
  },
}
</script>

<style scoped>

</style>
