<template>
  <div>

    <type-list-add-new
      v-if="$can('store', 'Type') && getCurrentTypes() && getCurrentCategories()"
      :is-add-new-type-sidebar-active.sync="isAddNewTypeSidebarActive"
      :categories="getCurrentCategories()"
      @refetch-data="refetchData"
    />

    <filter-list-add-new
      v-if="$can('store', 'Filter') && getCurrentTypes() && getCurrentCategories()"
      :is-add-new-filter-sidebar-active.sync="isAddNewFilterSidebarActive"
      :types="getCurrentTypes()"
      @refetch-data="refetchData"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          />
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">

              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-dropdown
                v-if="$can('store', 'Type') || $can('store', 'Filter')"
                id="dropdown-1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                text="Add"
                variant="primary"
              >
                <b-dropdown-item
                  v-if="$can('store', 'Type')"
                  @click="isAddNewTypeSidebarActive = true"
                >
                  Family Filters
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="$can('store', 'Filter')"
                  @click="isAddNewFilterSidebarActive = true"
                >
                  Filter
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        v-if="getCurrentTypes"
        ref="refTypesListTable"
        :items="fetchTypesCallBack"
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        empty-text="No types found"
        :sort-desc.sync="isSortDirDesc"
        show-empty
        striped
        responsive
      >

        <template #cell(actions)="row">
          <b-button
            size="sm"
            @click="row.toggleDetails"
          >
            show details
          </b-button>
        </template>

        <template #row-details="row">
          <b-card>
            <b-row class="mb-2">
              <b-col
                v-for="filter in row.item.filters"
                :key="filter.id"
                md="4"
                class="mb-1"
              >
                <b-row class="hoverborder py-1">
                  <b-col md="8">
                    <div class="d-flex">
                      <filter-edit-button
                        v-if="$can('update', 'Filter')"
                        :filter="filter"
                        @refetch-data="refetchData"
                      />
                      <div class="ml-0">
                        <span class="d-block">{{ filter.name.en }}</span>
                        <span class="text-muted d-block">
                          {{ filter.name.it }}
                        </span>
                      </div>
                    </div>
                  </b-col>
                  <b-col>
                    <div class="ml-1 mt-1">
                      <span class="d-block">
                        Items: {{ filter.related_items }}
                      </span>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <b-row
              v-if="row.item.categories.length > 0"
              class="mb-1"
            >
              <h5>Related Categories</h5>
            </b-row>

            <b-row
              v-if="row.item.categories.length > 0"
              class="mb-2"
            >
              <b-col
                v-for="category in row.item.categories"
                :key="category.id"
                md="2"
                class="mb-1"
              >
                <div class="d-flex">
                  <div class="ml-0">
                    <span class="d-block">{{ category.name.en }}</span>
                    <span class="text-muted d-block">
                      {{ category.name.it }}
                    </span>
                  </div>
                </div>
              </b-col>
            </b-row>

            <b-row
              v-if="row.item.subcategories.length > 0"
              class="mb-1"
            >
              <h5>Related Sub Categories</h5>
            </b-row>
            <b-row
              v-if="row.item.subcategories.length > 0"
              class="mb-2"
            >
              <b-col
                v-for="subcategory in row.item.subcategories"
                :key="subcategory.id"
                md="2"
                class="mb-1"
              >
                <div class="d-flex">
                  <div class="ml-0">
                    <span class="d-block">{{ subcategory.name.en }}</span>
                    <span class="text-muted d-block">
                      {{ subcategory.name.it }}
                    </span>
                  </div>
                </div>
              </b-col>
            </b-row>

            <b-button
              size="sm"
              variant="outline-secondary"
              @click="row.toggleDetails"
            >
              Hide Details
            </b-button>
          </b-card>
        </template>

        <template #cell(general)="data">
          <feather-icon
            v-if="data.item.general"
            size="25"
            icon="GlobeIcon"
          />
        </template>

        <template #cell(family_filters)="data">
          <div class="d-flex">
            <b-button
              v-if="$can('update', 'Type')"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="sm"
              variant="flat-primary"
              @click="$bvModal.show('modal-edit-type-'+data.item.id)"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <type-edit-button
              v-if="$can('update', 'Type')"
              :type="data.item"
              @refetch-data="refetchData"
            />
            <div class="ml-1">
              <span class="d-block">{{ data.item.name.en }}</span>
              <span class="text-muted d-block">
                {{ data.item.name.it }}
              </span>
            </div>
          </div>
        </template>

        <template #cell(info)="data">
          <b-badge
            v-if="data.item.published"
            variant="success"
            class="badge-glow mr-1"
          >
            Public
          </b-badge>
          <b-badge
            v-else
            variant="danger"
            class="badge-glow mr-1"
          >
            Private
          </b-badge>
          <b-badge
            v-if="data.item.multiple"
            variant="success"
            class="badge-glow mr-1"
          >
            Multiple
          </b-badge>
          <b-badge
            v-else
            variant="danger"
            class="badge-glow mr-1"
          >
            Single
          </b-badge>
          <b-badge
            v-if="data.item.required"
            variant="danger"
            class="badge-glow mr-1"
          >
            Required
          </b-badge>
          <b-badge
            v-else
            variant="success"
            class="badge-glow mr-1"
          >
            Not Required
          </b-badge>
        </template>

        <template #cell(public)="data">
          <b-badge
            v-if="data.item.published"
            variant="success"
            class="badge-glow mr-1"
          >
            Public
          </b-badge>
          <b-badge
            v-else
            variant="danger"
            class="badge-glow mr-1"
          >
            Private
          </b-badge>
        </template>

        <template #cell(required)="data">
          <b-badge
            v-if="data.item.required"
            variant="danger"
            class="badge-glow mr-1"
          >
            Required
          </b-badge>
          <b-badge
            v-else
            variant="success"
            class="badge-glow mr-1"
          >
            Not Required
          </b-badge>
        </template>

        <template #cell(multiple)="data">
          <b-badge
            v-if="data.item.multiple"
            variant="success"
            class="badge-glow"
          >
            Multiple
          </b-badge>
          <b-badge
            v-else
            variant="danger"
            class="badge-glow"
          >
            Single
          </b-badge>
        </template>

        <template #cell(category)="data">
          <div class="d-flex">
            <div
              v-if="data.item.categories.length === 1"
              class="ml-1"
            >
              <span class="d-block">{{ data.item.categories[0].name.en }}</span>
              <span class="text-muted d-block">
                {{ data.item.categories[0].name.it }}
              </span>
            </div>
            <div
              v-else-if="data.item.categories.length > 0"
              class="ml-1"
            >
              <span class="d-block">{{ data.item.categories.length }}</span>
            </div>
          </div>

          <div class="d-flex">
            <div
              v-if="data.item.subcategories.length === 1"
              class="ml-1"
            >
              <span class="d-block">{{ data.item.subcategories[0].name.en }}</span>
              <span class="text-muted d-block">
                {{ data.item.subcategories[0].name.it }}
              </span>
            </div>
            <div
              v-else-if="data.item.subcategories.length > 0"
              class="ml-1"
            >
              <span class="d-block">{{ data.item.subcategories.length }}</span>
            </div>
          </div>
        </template>

        <template #cell(related_items)="data">
          <div class="d-flex">
            <div class="ml-1">
              <span class="d-block">{{ resolveAllRealtedItemsToType(data.item) }}</span>
            </div>
          </div>
        </template>

      </b-table>

    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BBadge,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BDropdown,
  BDropdownItem,
  BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import useCategoriesList from '@/views/items/categories/categories-list/useCategoriesList'
import { avatarText } from '@core/utils/filter'
import useTypesList from '@/views/items/filters/filters-list/useTypesList'
import TypeListAddNew from '@/views/items/filters/filters-list/TypeListAddNew.vue'
import TypeEditButton from '@/views/items/filters/filters-list/TypeEditButton.vue'
import FilterListAddNew from '@/views/items/filters/filters-list/filters/FilterListAddNew.vue'
import FilterEditButton from '@/views/items/filters/filters-list/filters/FilterEditButton.vue'

export default {
  name: 'Filters',
  components: {
    BButton,
    BBadge,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BTable,

    TypeListAddNew,
    TypeEditButton,
    FilterListAddNew,
    FilterEditButton,
  },
  directives: {
    Ripple,
  },
  setup() {
    const isAddNewTypeSidebarActive = ref(false)
    const isAddNewFilterSidebarActive = ref(false)

    const {
      fetchCategories,
      getCurrentCategories,
      fetchCategoriesById,
      getCurrentSubCategory,

      resolveCategoryToTranslateVariant,

      resolveDataForVSelect,
      resolveDataForFilter,

      refCategoryListTable,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      groupFilter,
    } = useCategoriesList()

    const {
      refTypesListTable,
      fetchTypesCallBack,
      fetchTypes,
      getCurrentTypes,
      refetchData,
      sortBy,
      isSortDirDesc,
      perPageOptions,
      searchQuery,
      tableColumns,
      mapbyid,
    } = useTypesList()

    return {
      fetchTypesCallBack,
      fetchTypes,
      getCurrentTypes,

      // Sidebar
      isAddNewTypeSidebarActive,
      isAddNewFilterSidebarActive,
      fetchCategories,
      fetchCategoriesById,
      getCurrentCategories,
      getCurrentSubCategory,

      resolveCategoryToTranslateVariant,

      resolveDataForVSelect,
      resolveDataForFilter,

      refCategoryListTable,
      refTypesListTable,
      sortBy,
      isSortDirDesc,
      perPageOptions,
      searchQuery,
      tableColumns,
      mapbyid,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      groupFilter,
    }
  },
  computed: {
    listCategory() {
      return this.getCurrentCategories()[this.groupFilter].categories.filter(category => category.name.en.toLowerCase()
        .includes(this.searchQuery.toLowerCase()) || category.name.it.toLowerCase()
        .includes(this.searchQuery.toLowerCase()))
    },
  },
  mounted() {
    if (this.$can('store', 'Type') || this.$can('update', 'Type')) {
      this.fetchCategories()
      this.fetchCategoriesById()
    }
  },
  methods: {
    resolveAllRealtedItemsToType(data) {
      let counter = 0
      data.filters.forEach(value => {
        counter += value.related_items
      })
      return counter
    },
  },
}
</script>

<style lang="scss" scoped>
    .per-page-selector {
        width: 90px;
    }
</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
