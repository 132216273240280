<template>
  <b-sidebar
    id="add-new-category-sidebar"
    :visible="isAddNewTypeSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-type-sidebar-active', val)"
  >

    <template #default="{ hide }">

      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Family Filters
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <div class="demo-inline-spacing">

            <b-form-checkbox
              v-model="typeData.required"
              checked="true"
              class="custom-control-success"
              name="required"
              switch
            >
              Required
            </b-form-checkbox>

            <b-form-checkbox
              v-model="typeData.multiple"
              checked="true"
              class="custom-control-success"
              name="multiple"
              switch
            >
              Multiple
            </b-form-checkbox>

            <b-form-checkbox
              v-model="typeData.published"
              checked="true"
              class="custom-control-success"
              name="multiple"
              switch
            >
              Public
            </b-form-checkbox>

          </div>

          <b-form-checkbox
            v-model="typeData.general"
            v-b-tooltip.hover.left="'This option cannot be undone after saving'"
            checked="true"
            class="custom-control-success mt-1"
            name="general"
            switch
            @change="typeData.categories.group = null;
                     typeData.categories.category = null;
                     typeData.categories.subcategory = null"
          >
            General
            <span class="switch-icon-left">
              <feather-icon icon="GlobeIcon" />
            </span>
          </b-form-checkbox>

          <template v-if="typeData.general === false">
            <!-- Group -->
            <validation-provider
              #default="validationContext"
              name="Group"
              rules="required"
            >
              <b-form-group
                label="Group"
                label-for="group"
              >

                <v-select
                  id="group"
                  v-model="typeData.categories.group"
                  input-id="group"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :reduce="val => val.value"
                  :options="resolveSubCategories()"
                  :clearable="false"
                  :state="getValidationState(validationContext)"
                  trim
                  @input="typeData.categories.category = null"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Categories -->
            <validation-provider
              v-if="typeData.categories.group != null && resolveSubCategories(typeData.categories.group)"
              #default="validationContext"
              name="Categories"
              rules="required"
            >

              <b-form-group
                label=""
                label-for="categories"
              >

                <b-row>

                  <b-col>
                    <label>Categories</label>
                  </b-col>

                  <b-col class="text-right">
                    <b-button
                      size="sm"
                      variant="primary"
                      @click="selectAllCategories"
                    >
                      <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">Select all</span>
                    </b-button>
                  </b-col>
                </b-row>

                <v-select
                  ref="categories"
                  v-model="typeData.categories.category"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="resolveSubCategories(typeData.categories.group)"
                  :clearable="false"
                  input-id="categories"
                  :multiple="true"
                  @input="typeData.categories.subcategory = null"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Sub Categories -->
            <validation-provider
              v-if="typeData.categories.category != null && typeData.categories.category.length === 1 && resolveSubCategories(typeData.categories.group, typeData.categories.category.value)"
              #default="validationContext"
              name="Sub Categories"
              rules=""
            >
              <b-form-group
                label="Sub Categories"
                label-for="sub categories"
              >

                <v-select
                  v-model="typeData.categories.subcategory"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="resolveSubCategories(typeData.categories.group, typeData.categories.category[0].value)"
                  :clearable="false"
                  :multiple="true"
                  input-id="sub categories"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </template>

          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name EN"
            rules="required"
          >
            <b-form-group
              label="Name EN"
              label-for="name_en"
            >
              <b-form-input
                id="name_en"
                v-model="typeData.name.en"
                :state="getValidationState(validationContext)"
                trim
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Name IT"
            rules="required"
          >
            <b-form-group
              label="Name IT"
              label-for="name_it"
            >
              <b-form-input
                id="name_it"
                v-model="typeData.name.it"
                :state="getValidationState(validationContext)"
                trim
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-if="typeData.general === true || typeData.categories.category != null"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormCheckbox, BRow, BCol, VBTooltip,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useCategoriesList from '@/views/items/categories/categories-list/useCategoriesList'
import vSelect from 'vue-select'

export default {

  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormCheckbox,
    vSelect,
    BRow,
    BCol,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  model: {
    prop: 'isAddNewTypeSidebarActive',
    event: 'update:is-add-new-type-sidebar-active',
  },
  props: {
    isAddNewTypeSidebarActive: {
      type: Boolean,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    }
  },
  setup(props, { emit }) {
    // Use toast
    const toast = useToast()

    const blankTypeData = {
      general: false,
      required: false,
      multiple: false,
      published: false,
      categories: {
        group: null,
        category: null,
        subcategory: null,
      },
      name: {
        en: null,
        it: null,
      },
    }

    const typeData = ref(JSON.parse(JSON.stringify(blankTypeData)))
    const resetcategoryData = () => {
      typeData.value = JSON.parse(JSON.stringify(blankTypeData))
    }

    const {
      fetchCategories,
      getCurrentCategories,
    } = useCategoriesList()

    const onSubmit = () => {
      const sendTypeData = typeData.value
      if (sendTypeData.general === false) {
        sendTypeData.categories = sendTypeData.categories.subcategory != null ? sendTypeData.categories.subcategory.map(data => data.id) : sendTypeData.categories.category.map(data => data.id)
      }
      store.dispatch('types/addType', sendTypeData)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-type-sidebar-active', false)
        }).catch(error => {
          console.log(error.response)
          Object.values(error.response.data)
            .forEach(message => {
              toast({
                component: ToastificationContent,
                props: {
                  title: message[0],
                  icon: 'AlertTriangleIcon',
                  variant: 'warning',
                },
              })
            })
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetcategoryData)

    return {
      fetchCategories,
      getCurrentCategories,

      typeData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  methods: {
    resolveSubCategories(group = null, category = null) {
      if (group === null) {
        return this.getCurrentCategories()
          .map((data, index) => ({
            label: `${data.name.it} - ${data.name.en}`,
            value: index,
            id: data.id,
          }))
      }
      if (category === null) {
        return this.getCurrentCategories()[group].categories.map((data, index) => ({
          label: `${data.name.it} - ${data.name.en}`,
          value: index,
          id: data.id,
        }))
      }
      return this.getCurrentCategories()[group].categories[category].subcategories.map((data, index) => ({
        label: `${data.name.it} - ${data.name.en}`,
        value: index,
        id: data.id,
      }))
    },
    selectAllCategories() {
      const { categories } = this.$refs
      this.typeData.categories.category = categories.options

      // bug caused by onAfterSelect
      categories.open = false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
