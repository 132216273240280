<template>
  <b-sidebar
    id="add-new-filter-sidebar"
    :visible="isAddNewFilterSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-filter-sidebar-active', val)"
  >

    <template #default="{ hide }">

      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Filter
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Category -->
          <validation-provider
            #default="validationContext"
            name="type"
            rules="required"
          >
            <b-form-group
              label="Type"
              label-for="type"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="filterData.type_id"
                input-id="type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="resolveDataforVselectType()"
                :reduce="val => val.value"
                :clearable="false"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name EN"
            rules="required"
          >
            <b-form-group
              label="Name EN"
              label-for="name_en"
            >
              <b-form-input
                id="filter_name_en"
                v-model="filterData.name.en"
                :state="getValidationState(validationContext)"
                trim
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Name IT"
            rules="required"
          >
            <b-form-group
              label="Name IT"
              label-for="name_it"
            >
              <b-form-input
                id="filter_name_it"
                v-model="filterData.name.it"
                :state="getValidationState(validationContext)"
                trim
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import countries from '@/@fake-db/data/other/countries'
import vSelect from 'vue-select'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {

  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewFilterSidebarActive',
    event: 'update:is-add-new-filter-sidebar-active',
  },
  props: {
    isAddNewFilterSidebarActive: {
      type: Boolean,
      required: true,
    },
    types: {
      type: [Object, Array],
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    }
  },
  setup(props, { emit }) {
    // Use toast
    const toast = useToast()

    const blankFilterData = {
      type_id: null,
      name: {
        en: null,
        it: null,
      },
    }

    const filterData = ref(JSON.parse(JSON.stringify(blankFilterData)))
    const resetcategoryData = () => {
      filterData.value = JSON.parse(JSON.stringify(blankFilterData))
    }

    const onSubmit = () => {
      store.dispatch('types/addFilter', filterData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-filter-sidebar-active', false)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Filter create successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        }).catch(error => {
          console.log(error.response)
          Object.values(error.response.data)
            .forEach(message => {
              toast({
                component: ToastificationContent,
                props: {
                  title: message[0],
                  icon: 'AlertTriangleIcon',
                  variant: 'warning',
                },
              })
            })
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetcategoryData)

    return {
      filterData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  methods: {
    resolveDataforVselectType() {
      return Object.values(this.types).map(type => ({
        label: `${type.name.it} - ${type.name.en}`,
        value: type.id,
      }))
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  #add-new-filter-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
</style>
