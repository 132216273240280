<template>
  <!-- form -->
  <b-dropdown
    v-if="filter"
    :id="'edit-filter-'+filter.id"
    ref="filterEditButton"
    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
    right
    size="sm"
    variant="flat-primary"
    menu-class="w-300"
    class="dropdown-icon-wrapper"
  >
    <template #button-content>
      <feather-icon icon="EditIcon" />
    </template>

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-dropdown-form
        class="py-1"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <!-- Name -->
        <validation-provider
          #default="validationContext"
          name="Name EN"
          rules="required"
        >
          <b-form-group
            label="Name EN"
            label-for="name_en"
          >
            <b-form-input
              :id="'name_en'+filterData.id+filterData.type_id"
              v-model="filterData.name.en"
              :state="getValidationState(validationContext)"
              trim
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider
          #default="validationContext"
          name="Name IT"
          rules="required"
        >
          <b-form-group
            label="Name IT"
            label-for="name_it"
          >
            <b-form-input
              :id="'name_it'+filterData.id+filterData.type_id"
              v-model="filterData.name.it"
              :state="getValidationState(validationContext)"
              trim
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            Save
          </b-button>

        </div>
      </b-dropdown-form>
    </validation-observer>

    <template v-if="$can('destroy', 'Filter') && filter.related_items === 0">
      <b-dropdown-divider />
      <a
        class="btn text-danger"
        @click="swalConfirmDelete(filter.id)"
      >
        Delete
      </a>
    </template>

  </b-dropdown>
</template>

<script>
import {
  BButton,
  BDropdown,
  BDropdownForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BDropdownDivider,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import store from '@/store'
import countries from '@/@fake-db/data/other/countries'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'FilterEditButton',
  components: {
    BButton,
    BDropdown,
    BDropdownForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BDropdownDivider,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    filter: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    }
  },
  setup(props, { emit }) {
    // Use toast
    const toast = useToast()

    const blankSubCategoryData = {
      id: props.filter.id,
      type_id: props.filter.type_id,
      related_items: props.filter.related_items,
      name: {
        en: props.filter.name.en,
        it: props.filter.name.it,
      },
    }

    const filterData = ref(JSON.parse(JSON.stringify(blankSubCategoryData)))
    const resetcategoryData = () => {
      filterData.value = JSON.parse(JSON.stringify(blankSubCategoryData))
    }

    const onSubmit = () => {
      store.dispatch('types/updateFilter', filterData.value)
        .then(() => {
          emit('refetch-data')
          toast({
            component: ToastificationContent,
            props: {
              title: 'Filter update successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          console.log(error.response)
          Object.values(error.response.data)
            .forEach(message => {
              toast({
                component: ToastificationContent,
                props: {
                  title: message[0],
                  icon: 'AlertTriangleIcon',
                  variant: 'warning',
                },
              })
            })
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetcategoryData)

    return {
      filterData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  methods: {
    swalConfirmDelete(id) {
      this.$swal({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            store.dispatch('types/forceDeleteFilter', id)
              .then(() => {
                this.$emit('refetch-data')
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: 'Filter has been deleted.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              })
          } else if (result.dismiss === 'cancel') {
            this.$swal({
              title: 'Cancelled',
              text: 'Filter are safe :)',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
          return result.value
        })
    },
  },
}
</script>

<style scoped>

</style>
